const Modal = (props) => {
    var style;
    if(props.width) {
        style = {
            width: props.width
        }
    }
    return (
        <div className="modal-overlay">
            <div className="modal-body" style={style}>
                {props.children}
            </div>
        </div>
    );
}

export default Modal;