import React, { Component } from 'react';
import Modal from './Modal';
import axios from 'axios';
import { X } from 'react-feather'
import { ArrowLeft } from 'react-feather'
import { LogIn } from 'react-feather'
const api = process.env.API;
import Context from '../components/Context';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { firebase, auth } from '../firebase'

class EnterPhoneModal extends Component {
    static contextType = Context;

    state = {
        phoneNumber: "",
        errors: [],
    }

    componentDidMount = () => {
        this.setState({
            recaptchaVerifier: new firebase.auth.RecaptchaVerifier('sign-up-button', {
                'size': 'invisible',
                'callback': function(response) {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                }
            })
        }, () => {
            this.props.setAppVerifier(this.state.recaptchaVerifier);
        })
    }

    validate = () => {
        var errors = [];

        if(!isValidPhoneNumber(this.state.phoneNumber)) {
            errors.push("Invalid phone number.");
        }

        //clear past errors
        this.setState({
            errors: []
        });

        return errors;
    }

    changePhoneNumber = (phoneNumber) => {
        this.setState({
            phoneNumber: phoneNumber
        })
    }

    handleSignUp = (e) => {
        e.preventDefault();
        var errors = this.validate();
        console.log(errors);
        if(errors.length === 0) {
            this.props.handleSignUp(errors, this.state.phoneNumber);
        }
    }

    render() {
        return (
            <Modal>
                <div className="signUpModal">
                    <div className="topIcons">
                        <button className="close" onClick={this.props.toggle}>
                            <X color={"black"} />
                        </button>
                    </div>
                    <div className="headerTextBlock">
                        <div className="title">Join Realms</div>
                        <div className="description">Please enter your phone number</div>
                    </div>
                    <ul>
                        {this.state.errors.map((error) =>
                            <li>{error}</li>
                        )}
                    </ul>
                    <form>
                        <div className="phoneField">
                            <PhoneInput
                                placeholder="Phone number"
                                defaultCountry="US"
                                onChange={this.changePhoneNumber}
                                value={this.state.phoneNumber}
                            />
                            <button id="sign-up-button" onClick={this.handleSignUp} type="submit">→</button>
                        </div>
                    </form>
                    <div className="bottomText">
                        We'll send a verification code afterwards.
                    </div>
                </div>
            </Modal>
        )
    }
}

class VerifyPhoneModal extends Component {
    
    state = {
        code: ""
    }

    change = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
          [name]: value
        });
    }

    render() {
        return (
            <Modal>
                <div className="signUpModal">
                    <div className="topIcons">
                        <button className="back" onClick={() => this.props.changeStep(0)}>
                            <ArrowLeft color={"black"} />
                        </button>
                        <button className="close" onClick={this.props.toggle}>
                            <X color={"black"} />
                        </button>
                    </div>
                    <div className="headerTextBlock">
                        <div className="description">Enter the code we sent to {this.props.phoneNumber}</div>
                    </div>
                    <form>
                        <div className="phoneField" style={{width: "200px", margin: "auto"}}>
                            <input className="PhoneInputInput" style={{width: "150px"}} value={this.state.code} name="code" onChange={this.change} type="text" placeholder="Code"></input>
                            <button id="sign-up-button" onClick={(e) => {e.preventDefault(); this.props.handleVerifyCode(this.state.code)}} type="submit">→</button>
                        </div>
                    </form>
                    <div className="bottomText">
                        Resend code
                    </div>
                </div>
            </Modal>
        );
    }
}

class SignUpModal extends Component {

    static contextType = Context;

    state = {
        step: 0,
        appVerifier: null,
        phoneNumber: "",
        confirmationResult: null
    }

    setAppVerifier = (appVerifier) => {
        this.setState({
            appVerifier: appVerifier
        })
    }

    changeStep = (step) => {
        this.setState({
            step: step
        })
    }

    signUp = (errors, phoneNumber) => {
        var appVerifier = this.state.appVerifier;
        auth.signInWithPhoneNumber(phoneNumber, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                //move to next screen
                console.log(confirmationResult);
                this.setState({
                    phoneNumber: phoneNumber,
                    confirmationResult: confirmationResult
                });
                this.changeStep(1);
            }).catch(function (error) {
                // Error; SMS not sent
                // ...
                if(error) {
                    console.log(error);
                }
            });
    }

    verifyCode = (code) => {
        this.state.confirmationResult.confirm(code)
            .then((result) => {
                // User signed in successfully.
                var user = result.user;
                var credential = firebase.auth.PhoneAuthProvider.credential(this.state.confirmationResult.verificationId, code);
                //Create user if doesn't already exist
                axios.post(api + "/users/signup", {
                    user: {
                        uid: auth.currentUser.uid,
                        phoneNumber: this.state.phoneNumber
                    }
                })
                .then((res) => {
                    if(res) {
                        const user = res.data.user;
                        // identify(user._id, user.email, user.username);
                        // track("Signed Up", user);
                        // this.context.actions.signIn(user);
                        if(this.props.handleSignUpInvite) {
                            this.props.handleSignUpInvite(user._id);
                        }
                        if(this.props.handleSignIn) {
                            this.props.handleSignIn();
                        }
                        else {
                            if(res.data.isNewAccount === true) {
                                window.location = '/welcome';
                            }
                            else {
                                window.location = '/'
                            }
                        }
                    }
                })
                .catch((error) => {
                    if(error.response) {
                        console.log(error.response);
                        errors.push(error.response.data.message);
                        this.setState({
                            errors: errors
                        })
                    }
                })
            })
            .catch(err => {
                if(err) {
                    console.log(err);
                    alert(err.message);
                }
            })
    }

    render() {
        if(this.state.step === 0) {
            return (
                <EnterPhoneModal toggle={this.props.toggle} changeStep={this.changeStep} handleSignUp={this.signUp} setAppVerifier={this.setAppVerifier} />
            );
        }
        else if(this.state.step === 1) {
            return (
                <VerifyPhoneModal toggle={this.props.toggle} changeStep={this.changeStep} handleVerifyCode={this.verifyCode} confirmationResult={this.state.confirmationResult} phoneNumber={this.state.phoneNumber} />
            )
        }
    } 
}

export default SignUpModal;
